import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { slideInRightOnEnterAnimation, slideInLeftOnEnterAnimation } from 'angular-animations';
import { catchError, of, tap } from 'rxjs';
import { allRegex } from 'src/app/core/extensions/regex';
import { getUserRole } from 'src/app/core/extensions/userRole.extension';
import { WeBoostYourClinicContact, WeBoostYourClinicContactList, WeBoostYourClinicSurvey, WeBoostYourClinicSurveyList } from 'src/app/core/models/contact/weBoostYourClinic';
import { ContactsService } from 'src/app/core/services/contact/contacts.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-we-boost-your-clinic',
  templateUrl: './we-boost-your-clinic.component.html',
  styleUrl: './we-boost-your-clinic.component.scss',
  animations: [slideInRightOnEnterAnimation({ anchor: 'enterRight', duration: 400 }),
  slideInLeftOnEnterAnimation({ anchor: 'enterLeft', duration: 400 })]
})
export class WeBoostYourClinicComponent {

  public contactData!: WeBoostYourClinicContactList;
  public contactsList!: WeBoostYourClinicContact[];
  public offset: number = 10
  public currentPage!: number;
  public paginationParams: HttpParams = new HttpParams();
  public detailData: any
  public contactSelected!: any;
  dataSource!: MatTableDataSource<WeBoostYourClinicContact>
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public showAlert!: boolean;
  public alertData: any;
  public showCc: boolean = false;
  public loader!: boolean;

  public ccMails: string[] = [];
  public ccSelected: FormControl = new FormControl('', [Validators.required, Validators.pattern(allRegex.regexPhoneAndEmail)]);

  displayedColumns: string[] = [
    'createdAt',
    'username',
    'email',
    'phone',
    'interestedIn',
    'verified',
    'detail'
  ]

  constructor(private contactsService: ContactsService, private dialog: MatDialog, private datePipe: DatePipe, private router: Router) { }

  ngOnInit() {
    this.paginationParams = this.paginationParams.set('page', 1).set('size', this.offset)
    this.loader = true
    this.getContacts()
  }

  getContacts() {
    this.contactsService.getWeBoostYourClinicContactsList(this.paginationParams).subscribe(res => {
      this.contactData = res
      this.contactsList = res.data
      this.dataSource = new MatTableDataSource<WeBoostYourClinicContact>(this.contactsList);
      if (this.contactData) {
        this.loader = false
      }
    })

  }

  //evento para obtener la paginación
  onPageChange(event: any) {
    this.paginationParams = this.paginationParams.set('page', event.pageIndex + 1)
    this.currentPage = event.pageIndex;
    this.getContacts()
  }

  goBackToTable() {
    this.showAlert = false;
    this.detailData = null
  }

  showContact() {
    this.showCc = !this.showCc;
  }

  setContactDetail(contact: WeBoostYourClinicContact) {
    this.showAlert = false;
    this.contactSelected = contact;
    if (contact.filled_survey) {
      this.contactsService.getWeBoostYourClinicSurvey(contact.idSurvey).pipe(
        tap((res) => {
          const surveyData = res;
          this.detailData = {
            icon: 'feed',
            title: 'Detalle del formulario',
            data: [
              {
                name: 'Fecha de creación',
                value: contact.createdAt,
              },
              {
                name: 'Nombre',
                value: contact.username,
              },
              {
                name: 'Email',
                value: contact.email,
              },
              {
                name: 'Teléfono',
                value: contact.phone,
              },
              {
                name: 'interesado en',
                value: contact.interestedIn,
              },
              {
                name: 'Email validado',
                value: contact.verified ? 'Sí' : 'No',
              },
              {
                name: 'Rol',
                value: surveyData.clinicRole,
              },
              {
                name: 'Años de experiencia',
                value: surveyData.yearsExperience,
              },
            ]
          }
  
          if (surveyData.clinicRole === 'Propietario') {
            this.detailData.data.push(
              {
                name: 'Número de clínicas',
                value: surveyData.clinicNumber || '',
              },
              {
                name: 'Última apertura',
                value: surveyData.lastOpeningYear || '',
              },
              {
                name: 'Ingresos mensuales',
                value: surveyData.monthlyBilling || '',
              }
  
            )
          }
        }),
        catchError((err) => {
          window.alert('hubo un error inesperado')
          return of(null)
        })
      ).subscribe()
    } else {
      this.detailData = {
        icon: 'feed',
        title: 'Detalle del formulario',
        data: [
          {
            name: 'Fecha de creación',
            value: contact.createdAt,
          },
          {
            name: 'Nombre',
            value: contact.username,
          },
          {
            name: 'Email',
            value: contact.email,
          },
          {
            name: 'Teléfono',
            value: contact.phone,
          },
          {
            name: 'interesado en',
            value: contact.interestedIn,
          },
          {
            name: 'Email validado',
            value: contact.verified ? 'Sí' : 'No',
          },
        ]
      }

    }
   

  }
  tableData(): boolean {
    return this.dataSource && this.dataSource.data && this.dataSource.data.length > 0;
  }

}
