<div class="section_header">
    <p class="section_title"><mat-icon class="section_icon">masks</mat-icon> Impulsamos tu clínica</p>
</div>

<div *ngIf="detailData" [@enterRight]>
    <div class="buttons_container">
        <button class="main-filled-button" *ngIf="detailData" (click)="goBackToTable()"><mat-icon
                class="add-button">arrow_back</mat-icon>Volver</button>
    </div>
    <app-contact-detail [data]="detailData"></app-contact-detail>
</div>
<div class="tableMaterial" *ngIf="!detailData" [@enterLeft]>
    <table mat-table [dataSource]="dataSource" class="table-container">
        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>Fecha creación</th>
            <td mat-cell *matCellDef="let element"> {{element.createdAt | date: 'dd-MM-YYYY'}} </td>
        </ng-container>
        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef>Nombre</th>
            <td mat-cell *matCellDef="let element">{{ element.username }}
            </td>
        </ng-container>
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>
        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef>Teléfono</th>
            <td mat-cell *matCellDef="let element">{{element.phone}}</td>
        </ng-container>
        <ng-container matColumnDef="interestedIn">
            <th mat-header-cell *matHeaderCellDef>Interés</th>
            <td mat-cell *matCellDef="let element"> {{element.interestedIn}} </td>
        </ng-container>
        <ng-container matColumnDef="verified">
            <th mat-header-cell *matHeaderCellDef>Email validado</th>
            <td mat-cell *matCellDef="let element"> {{element.verified ? 'Sí' : 'No'}} </td>
        </ng-container>
        <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef>Detalle</th>
            <td mat-cell *matCellDef="let element">
                <div class="icon-link" (click)="setContactDetail(element)">
                    <mat-icon>open_in_new</mat-icon>
                </div>
            </td>

        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;  let i = index"></tr>
    </table>
    <p *ngIf="!tableData() && !loader">No se han encontrado usuarios.</p>
    <mat-paginator *ngIf="tableData()" class="paginator_container" [hidePageSize]="true" [length]="contactData.total"
        [pageSize]="offset" (page)="onPageChange($event)"></mat-paginator>
    <div class="skeleton-container" *ngIf="loader">
        <ngx-skeleton-loader count="{{ offset - 1 }}" appearance="line" [theme]="{ height: '36px' }">
        </ngx-skeleton-loader>
    </div>
</div>