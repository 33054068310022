import { Component, Input } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrl: './contact-detail.component.scss'
})
export class ContactDetailComponent {
@Input() data:any
ngOnInit() {
  this.processData();
}

processData() {
  this.data.data = this.data.data.map((item: any )=> {
    if (item.name === 'Disponibilidad' && (item.value === null || item.value === undefined)) {
      return { ...item, value: 'No definida' };
    }
    return item;
  });
}
}
