import { AurenContactsList } from '../../models/contact/aurenContact';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RentingContactsList } from '../../models/contact/rentingContact';
import { ContactContactsList } from '../../models/contact/contactContact';
import { EmploymentContactsList } from '../../models/contact/employmentContact';
import { InsuranceContactsList } from '../../models/contact/insuranceContact';
import { TurnkeyContactsList } from '../../models/contact/turnkeyContact';
import { LoanContactsList } from '../../models/contact/loanContact';
import { WeBoostYourClinicContactList, WeBoostYourClinicSurvey, WeBoostYourClinicSurveyList } from '../../models/contact/weBoostYourClinic';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(private http: HttpClient) { }

  //* LISTAS

  getAurenContactsList(params?: HttpParams): Observable<AurenContactsList> {
    return this.http.get<AurenContactsList>(`${environment.contactUrl}/auren/contacts`, { params })
  }

  getRentingContactsList(params?: HttpParams): Observable<RentingContactsList> {
    return this.http.get<RentingContactsList>(`${environment.contactUrl}/renting/contacts`, { params })
  }

  getContactContactsList(params?: HttpParams): Observable<ContactContactsList> {
    return this.http.get<ContactContactsList>(`${environment.contactUrl}/contact-us/contacts`, { params })
  }

  getEmploymentContactsList(params?: HttpParams): Observable<EmploymentContactsList> {
    return this.http.get<EmploymentContactsList>(`${environment.contactUrl}/employment/contacts`, { params })
  }
  getInsurancesContactsList(params?: HttpParams): Observable<InsuranceContactsList> {
    return this.http.get<InsuranceContactsList>(`${environment.contactUrl}/insurances/contacts`, { params })
  }
  getLoansContactsList(params?: HttpParams): Observable<LoanContactsList> {
    return this.http.get<LoanContactsList>(`${environment.contactUrl}/loans/contacts`, { params })
  }
  getWeBoostYourClinicContactsList(params?: HttpParams): Observable<WeBoostYourClinicContactList> {
    return this.http.get<WeBoostYourClinicContactList>(`${environment.contactUrl}/weboostyourclinic/getcontacts`, { params })
  }

  getWeBoostYourClinicSurveysList(params?: HttpParams): Observable<WeBoostYourClinicSurveyList> {
    return this.http.get<WeBoostYourClinicSurveyList>(`${environment.contactUrl}/weboostyourclinic/getsurveys`, { params })
  }

  getWeBoostYourClinicSurvey(id: string): Observable<WeBoostYourClinicSurvey> {
    return this.http.get<WeBoostYourClinicSurvey>(`${environment.contactUrl}/weboostyourclinic/getsurvey/${id}`)
  }
  
  public getFormOdontologist(params?: HttpParams): Observable<any>{
    return this.http.get(`${environment.contactUrl}/odontologists/contacts`, { params })
  }

  public getTurnkey(params?: HttpParams): Observable<TurnkeyContactsList>{
    return this.http.get<TurnkeyContactsList>(`${environment.contactUrl}/odontologists/contacts/turnkey`, { params })
  }

  //* ENVIO DE EMAILS  

  sendAurenContact(contactId:string, body?: any): Observable<any>{
    return this.http.post<any>(`${environment.contactUrl}/auren/contact/${contactId}/send`, body)
  }

  sendRentingContact(contactId:string, body?: any): Observable<any>{
    return this.http.post<any>(`${environment.contactUrl}/renting/contact/${contactId}/send`, body)
  }

  sendContactContact(contactId:string, body?: any): Observable<any>{
    return this.http.post<any>(`${environment.contactUrl}/contact-us/${contactId}/send`, body)
  }

  //* DESCARGA DE ARCHIVOS

  downloadFile(documentId: string): Observable<Blob> {
    return this.http.get<Blob>(`${environment.contactUrl}/documents/${documentId}/download`, { responseType: 'blob' as 'json' })
  }

}
